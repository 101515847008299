import Vue from "vue";

import AOS from "aos";
import axios from "axios";
import { isMobile, isMobileOnly } from "mobile-device-detect";
import VueAwesomeSwiper from "vue-awesome-swiper";
import VueOnlineProp from "vue-online-prop";

import "./registerServiceWorker";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import "aos/dist/aos.css";
import "swiper/css/swiper.css";

import vuetify from "@/plugin/vuetify";

// const baseDomain = "http://elitevoyages.test/";
const baseDomain = "https://api.elitevoyages.co.in/";
Vue.prototype.$base_domain = baseDomain;

Vue.prototype.$isMobile = !!isMobile;
Vue.prototype.$isMobileOnly = !!isMobileOnly;

axios.defaults.baseURL = `${baseDomain}`;
Vue.prototype.$axios = axios;

Vue.use(VueOnlineProp);
Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false;

new Vue({
	created() {
		AOS.init();
	},
	render: (h) => h(App),
	router,
	store,
	vuetify,
}).$mount("#app");
