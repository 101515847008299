import Vue from "vue";

import Vuetify from "vuetify";

import "@mdi/font/css/materialdesignicons.css";
import "assets/css/main.css";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const vuetify = new Vuetify({
	theme: {
		options: { customProperties: true },
		dark: true,
		themes: {
			light: {
				primary: "#f1962e",
				secondary: "#117191",
				accent: "#000000",
				error: "#dc3545",
				info: "#17a2b8",
				success: "#28a745",
				warning: "#ffc107",
				white: "#ffffff",
				black: "#000000",
				bg1: "#212121",
				bg2: "#333333",
				primaryText: "#000000",
				secondaryText: "#ffffff",
			},
			dark: {
				primary: "#f1962e",
				secondary: "#117191",
				accent: "#ffffff",
				error: "#dc3545",
				info: "#17a2b8",
				success: "#28a745",
				warning: "#ffc107",
				white: "#ffffff",
				black: "#000000",
				bg1: "#212121",
				bg2: "#333333",
				primaryText: "#ffffff",
				secondaryText: "#000000",
			},
		},
	},
	icons: {
		iconfont: "mdi",
	},
});

export default vuetify;
