import Vue from "vue";

import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		component: () => import(/* webpackChunkName: "Base" */ "../views/base/Base.vue"),
		children: [
			{
				path: "/",
				name: "Home",
				component: () => import(/* webpackChunkName: "Home" */ "../views/base/Home.vue"),
			},
			{
				path: "voyages-for-you",
				name: "Voyages-for-you",
				props: true,
				component: () => import(/* webpackChunkName: "TourSearch" */ "../views/base/TourSearch.vue"),
			},
			{
				path: "package/:name",
				name: "Tour Details",
				props: true,
				component: () => import(/* webpackChunkName: "Package" */ "../views/base/TourDetails.vue"),
			},
			{
				path: "document",
				name: "Document",
				component: () => import(/* webpackChunkName: "Document" */ "../views/base/Document.vue"),
			},
			{
				path: "*",
				name: "Error",
				component: () => import(/* webpackChunkName: "error" */ "../views/base/error.vue"),
			},
		],
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to) {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: "smooth",
			};
		}
		return "";
	},
});

export default router;
